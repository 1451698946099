$('.slider').slick({
  autoplay: false,//自動的に動き出すか。初期値はfalse。
  infinite: true,//スライドをループさせるかどうか。初期値はtrue。
  slidesToShow: 3,//スライドを画面に3枚見せる
  slidesToScroll: 3,//1回のスクロールで3枚の写真を移動して見せる
  dotsClass:'slick-dots',
  prevArrow: '<img src="/assets/icon/btn_left.svg" class="slide-arrow prev-arrow">',
  nextArrow: '<img src="/assets/icon/btn_right.svg" class="slide-arrow next-arrow">',

  dots: true,//下部ドットナビゲーションの表示
  responsive: [
    {
    breakpoint: 769,//モニターの横幅が769px以下の見せ方
    settings: {
      slidesToShow: 1,//スライドを画面に○枚見せる
      slidesToScroll: 1,//1回のスクロールで○枚の写真を移動して見せる
      centerMode: true,
      centerPadding: "8%"
    }
    },
    {
      breakpoint: 426,//モニターの横幅が426px以下の見せ方
      settings: {
        slidesToShow: 1,//スライドを画面に○枚見せる
        slidesToScroll: 1,//1回のスクロールで○枚の写真を移動して見せる
        centerMode: true,
        centerPadding: "8%"
      }
    }
  ]
});